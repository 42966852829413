import { render, staticRenderFns } from "./TinvAdd.vue?vue&type=template&id=cfd6d230&scoped=true&"
import script from "./TinvAdd.vue?vue&type=script&lang=js&"
export * from "./TinvAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfd6d230",
  null
  
)

export default component.exports